import { useCallback, useState } from "react";

import { Loader, TextArea } from "@storybook";
import { FILE_EXTENSION_URL, useMintApis } from "../store";
import { useNotification } from "hooks";
import { formatFileSize } from "utils";

export const RejectModal = ({
  selectedAsset,
  handelFilter,
  handeCloseReject,
}: any) => {
  const [openDocNumber, setOpenDocNumber] = useState<any>(0);
  const [fileList, setFileList] = useState<any[]>([]);

  const [reason, setReason] = useState<any>("");
  const { mintAssetAction, loading: mintAssetActionLoading } = useMintApis();
  const { successNotification, errorNotification } = useNotification();

  const handelInput = useCallback((e: any) => {
    const { value } = e.target;
    setReason(value);
  }, []);

  const handelOpenDocList = useCallback(
    (index: number) => {
      openDocNumber === index ? setOpenDocNumber(-1) : setOpenDocNumber(index);
    },
    [openDocNumber]
  );

  const handelView = useCallback((fileUrl: string | URL) => {
    window.open(fileUrl, "_blank");
  }, []);

  const handelInputReason = useCallback(
    (file: any, e: any) => {
      const { value } = e.target;

      // Check if the object already exists in the array
      const index = fileList.findIndex((obj) => obj.fileId === file.id);

      if (index !== -1) {
        // If the object exists, update its state
        const updatedObjects = [...fileList];
        updatedObjects[index] = {
          fileId: file.id,
          fileName: file.docName,
          reason: value,
        };
        setFileList(updatedObjects);
      } else {
        // If the object doesn't exist, add it to the array
        setFileList((prevObjects) => [
          ...prevObjects,
          { fileId: file.id, fileName: file.docName, reason: value },
        ]);
      }
    },
    [fileList]
  );

  const handelViewClose = useCallback(
    (file: any) => {
      setOpenDocNumber(-1);
      // Check if the object already exists in the array
      const index = fileList.findIndex((obj) => obj.fileId === file.id);

      if (index !== -1) {
        // If the object exists, update its state
        fileList.splice(index, 1);
      }
    },
    [fileList]
  );

  console.log(fileList);
  const reasonValue = useCallback(
    (item: any) => {
      const fileterFile = fileList.filter(
        (file: any) => file.fileId === item?.id
      );
      return fileterFile?.length ? fileterFile[0].reason : "";
    },
    [fileList]
  );

  const handelReject = useCallback(() => {
    if (reason === "") {
      errorNotification("Please enter reason of rejection");
      return;
    }
    if (mintAssetActionLoading) return;
    mintAssetAction(selectedAsset?.id, {
      status: "REJECTED",
      fileData: fileList,
      rejectionReason: reason,
    }).then((res: any) => {
      if (res?.success) {
        handelFilter({ name: "", offset: 0 });
        handeCloseReject();
        successNotification("Asset Rejected");
      } else {
        errorNotification(res?.message);
      }
    });
  }, [
    errorNotification,
    fileList,
    handeCloseReject,
    handelFilter,
    mintAssetAction,
    mintAssetActionLoading,
    reason,
    selectedAsset?.id,
    successNotification,
  ]);

  return (
    <div className="reject-auction">
      <h3>Rejection of asset creation</h3>
      <p>Add reason for rejection and add and attach necessary documents </p>
      <div className="reject--auction">
        Reason for rejection (Required)
        <TextArea
          handleChange={handelInput}
          label={""}
          value={reason}
          placeholder={""}
          row={2.5}
        />
        {selectedAsset?.dataRoom?.length > 0 && (
          <>
            <h3>Files</h3>
            {selectedAsset?.dataRoom.map((item: any, index: number) => (
              <div className="support-doc">
                <div className="support--doc">
                  {" "}
                  <div className="doc-img">
                    <img
                      src={
                        FILE_EXTENSION_URL[
                          item?.docName?.split(".").pop().toLowerCase()
                        ]
                      }
                      alt=""
                      data-tooltip-id={item?.id}
                    />
                    <div className="support--doc_name">{item?.docName}</div>
                    <div className="dot"></div>{" "}
                    {formatFileSize(item?.fileSize, 2)}
                  </div>
                  <div className="support-action">
                    <button onClick={() => handelOpenDocList(index)}>
                      Add comment
                    </button>
                    <button onClick={() => handelView(item?.fileUrl)}>
                      View
                    </button>
                  </div>
                </div>
                {openDocNumber === index && (
                  <div>
                    <div className="cooment-add">
                      Comment{" "}
                      <i
                        className="ri-close-line"
                        onClick={() => handelViewClose(item)}
                      ></i>
                    </div>
                    <TextArea
                      handleChange={(e) => handelInputReason(item, e)}
                      label={""}
                      value={reasonValue(item) as any}
                      placeholder={""}
                      row={3}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div className="action-btns">
        <button className="approve" onClick={handeCloseReject}>
          Cancel
        </button>
        <button className="reject" onClick={handelReject}>
          {" "}
          {mintAssetActionLoading ? <Loader dimension={20} /> : "Reject"}
        </button>
      </div>
    </div>
  );
};
