import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCookie, useNotification, useWehAuthn } from "hooks";
import {
  LoginRoutesState, MobileVerificationState, ApprovalStatusState,
  OptionsResponseState,
  userLoggedInDetails,
  userTokenState,
} from "../../store/state";
import style from "./choose-method.module.sass";
import { useMobileAuthentication, useBiometricAuthentication, useLogin } from "../../store/hooks";
import { Loader } from "@storybook";
import { Confirmation } from "@storybook";
import { ReactModal } from "@storybook";
import { useLocation } from "hooks";
import { Nosupported } from "@storybook";

export const ChooseMethod = () => {
  const userAuthDetails = useRecoilValue(userLoggedInDetails);
  const {
    verifyRegistration,
    authenticateOptions,
    registrationApproval,
    registrationOptions,
    verifyAuthentication,
  } = useBiometricAuthentication();
  const { registerNewCredential, authenticateDevice, getWebAuthnSupported } =
    useWehAuthn();
  const { errorNotification, successNotification } = useNotification();
  const { set: setCookieToken } = useCookie();
  const { handleOnSuccess, } = useLogin();
  const setUserToken = useSetRecoilState(userTokenState);
  const seLoginRoutes = useSetRecoilState(LoginRoutesState);
  const setMovileVerificationState = useSetRecoilState(MobileVerificationState);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const verficationStatus = useRecoilValue(ApprovalStatusState);
  const optionsResponse = useRecoilValue(OptionsResponseState);
  const [isNotSupportedModalVisible, setIsNotSupportedModalVisible] =
    useState(false);
  const { generateVerificationLink, loading: mobileVerificationLinkLoading } =
    useMobileAuthentication();

  const verificationID = useRecoilValue(MobileVerificationState);
  const { locationInfo } = useLocation();
  const [ipAddress, setIPAddress] = useState("");
  const setVerificationStatus = useSetRecoilState(ApprovalStatusState);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const handleAuthenticationSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payload = {
          type: "verifyAuthentication",
          authenticateOptions: res.authenticateOptions ?? {},
          id: res.id ?? "",
        };
        const resp: any = await verifyAuthentication(payload);
        const { isAuthenticated, token } = resp?.apiData?.data ?? {};
        if (token && isAuthenticated) {
          loginUser(token, "Logged in successfully");
        } else {
          setIsConfirmationOpen(true);
        }
        return;
      }
    },
    [verifyAuthentication]
  );

  const handleWebAuth = useCallback(async () => {
    if (isbiometricLoading) return;
    if (await getWebAuthnSupported()) {
      setIsbiometricLoading(true);

      const { countryCode, phone } = userAuthDetails;
      let payloadForAuthenticateCredentials = {};

      payloadForAuthenticateCredentials = {
        type: "authenticateOpts",
        countryCode,
        phone,
      };

      const resAuthenticateCredentials = await authenticateOptions(
        payloadForAuthenticateCredentials
      );

      const { id }: any = resAuthenticateCredentials ?? {};

      if (id) {
        authenticateDevice(
          handleAuthenticationSuccess,
          resAuthenticateCredentials ?? {},
          setIsConfirmationOpen
        );
      } else {
        setIsConfirmationOpen(true);
      }

      setIsbiometricLoading(false);
    } else {
      // open modal that web auth not supported
      setIsNotSupportedModalVisible(true);
    }
  }, [authenticateDevice, authenticateOptions, getWebAuthnSupported, handleAuthenticationSuccess, isbiometricLoading, userAuthDetails]);

  const handleMobileApp = useCallback(async () => {
    const { countryCode, phone } = userAuthDetails;
    const verificationId = await generateVerificationLink({
      type: "phone",
      countryCode: countryCode,
      phone: phone
    });

    if (verificationId) {
      successNotification("SMS sent successfully")
      setMovileVerificationState(verificationId);
      seLoginRoutes("mobilelinkVerify");

    }
  }, []);

  const handleGoBack = useCallback(() => {
    seLoginRoutes("email");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verficationStatus === "approved") {
      startRegisterDevice();
    }
  }, [verficationStatus]);

  const startRegisterDevice = useCallback(async () => {
    if (verficationStatus === "approved") {
      // if user already approved mobile link
      await registrationOptions({ authenticationType: "login" });
    } else {
      const { countryCode, phone } = userAuthDetails;

      const approvalPayload = {
        type: "registrationApproval",
        phone,
        countryCode,
        deviceInfo: navigator.userAgent ?? {},
      };
      const response = await registrationApproval(approvalPayload);
      if (response) {
        const { message, success, verificationId } = response?.apiData?.data;
        if (message !== "generate verification id.") {
          if (success && verificationId) {
            setMovileVerificationState(verificationId);
            seLoginRoutes("biometriclinkVerify");
            successNotification(message);
            return;
          }
          errorNotification(message);
          return;
        } else {
          setMovileVerificationState(verificationId);
          setVerificationStatus("approved");
          return;
        }
      }
    }
  }, [errorNotification, registrationApproval, registrationOptions, seLoginRoutes, setMovileVerificationState, setVerificationStatus, successNotification, userAuthDetails, verficationStatus]);

  const handleRegistrastionSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payloadSaveCredential = {
          type: "verifyRegistration",
          registrationOptResponse: res,
          id: optionsResponse?.id,
          verificationId: verificationID,
          deviceInfo: {
            location: locationInfo,
            ip: ipAddress,
          },
        };
        const resp: any = await verifyRegistration(payloadSaveCredential);
        const { isRegistered, token } = resp?.apiData?.data ?? {};

        if (isRegistered) {
          if (token) {
            loginUser(token, "Device registered and logged in successfully.");
          }
        } else {
          errorNotification("Login failed");
        }
      }
    },
    [
      optionsResponse?.id,
      verificationID,
      locationInfo,
      verifyRegistration,
      successNotification,
    ]
  );

  const loginUser = (token: string, message: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      localStorage.setItem("token", JSON.stringify(token));
      setUserToken(token);
      handleOnSuccess();
      successNotification(message);
    }
  };

  useEffect(() => {
    if (
      verficationStatus === "approved" &&
      optionsResponse?.registrationOptions
    ) {
      registerNewCredential(
        handleRegistrastionSuccess,
        optionsResponse?.registrationOptions ?? {}
      );
    }
    // eslint-disable-next-line
  }, [optionsResponse]);

  const handalConfimation = useCallback(
    async (isOpen: boolean, value: boolean) => {
      setIsConfirmationOpen(isOpen);
      if (value) {
        await startRegisterDevice();
      }
    },
    [setIsConfirmationOpen, startRegisterDevice]
  );

  const renderItem = useMemo(() => {
    return (
      <>
        <div className={style.wrapper}>
          <div className={style.inner_wrapper}>
            <div className={style.inner}>
              <div>
                <div className={style.title}>Choose Method</div>
                <div className={style.sub_title}>
                  Select the method through which you want to sign in.
                </div>
              </div>
              <div className={style.lists}>
                <div className={style.list_item} onClick={handleMobileApp}>
                  <div className={style.left_icon}>
                    <i className="ri-smartphone-line"></i>
                  </div>
                  <div className={style.method_title}>
                    Approval via text
                  </div>
                  <div className={style.right_icon}>
                    {mobileVerificationLinkLoading ? (
                      <Loader className="loader-blue" dimension={30} />
                    ) : (
                      <i className="ri-arrow-right-s-line"></i>
                    )}
                  </div>
                </div>

                <div className={style.list_item} onClick={handleWebAuth}>
                  <div className={style.left_icon}>
                    <i className="ri-fingerprint-line"></i>
                  </div>
                  <div className={style.method_title}>Device Biometric</div>
                  <div className={style.right_icon}>
                    {isbiometricLoading ? (
                      <Loader className="loader-blue" dimension={30} />
                    ) : (
                      <i className="ri-arrow-right-s-line"></i>
                    )}
                  </div>
                </div>

                <div className={style.back_btn} onClick={handleGoBack}>
                  <div className={style.back_title}>Back</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, [handleGoBack, handleMobileApp, handleWebAuth, isbiometricLoading, mobileVerificationLinkLoading]);

  const renderConfirmationModal = useMemo(() => {
    return (
      <ReactModal
        visible={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      >
        <Confirmation
          title={"Look like your browser is not registered for webAuthn!"}
          visible={isConfirmationOpen}
          description={
            "Please register this browser for WebAuthn before using device biometric login."
          }
          handleModal={handalConfimation}
          boldDescription=""
          label="Register for webAuthn"
          // cancelLabel="Close"
          type="buy"
        />
      </ReactModal>
    );
  }, [isConfirmationOpen, handalConfimation]);

  const renderNotSupportedModal = useMemo(() => {
    return (
      <ReactModal
        visible={isNotSupportedModalVisible}
        onClose={() => setIsNotSupportedModalVisible(false)}
      >
        <Nosupported
          title={"Look like your browser doesn’t support WebAuthn"}
          visible={isNotSupportedModalVisible}
          description={
            "Your browser does not support WebAuthn. Please try other available method."
          }
          handleModal={() => setIsNotSupportedModalVisible(false)}
          boldDescription=""
          label={"Close"}
          type="Secondary"
        />
      </ReactModal>
    );
  }, [isNotSupportedModalVisible]);

  return (
    <>
      {renderItem}
      <div className="position-top">
        {renderNotSupportedModal}
        {renderConfirmationModal}
      </div>
    </>
  );
};

