import { atom } from "recoil";
import { Json } from "types";


const ASSETS_STATUS = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  
};
export const assetListSbaState = atom<Json>({
  key: "asset-list-sba-state",
  default: {},
});

export const selectedAssetState = atom<Json>({
  key: "selected-sba-asset-state",
  default: {},
});

export const assetListCall = atom<boolean>({
  key: "sba-asset-list-call-state",
  default: false,
});

export const initalFilterStaate = atom<Json>({
  key: "sba-initail-filter-state",
  default: {
    limit: 10,
    offset: 0,
    type: "sba7",
    status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
  },
});
