// packages import
import { useCallback, useEffect, useMemo,  useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { database } from "libs";
import { ref, onValue } from "firebase/database";
import { ENVIRONMENT } from "envs";

// import { useSetRecoilState } from "recoil";

import { Image } from "@storybook";
import { useCookie, useAccess, useNetwork } from "hooks";
// import { userTokenState } from "views";
import { SBA7A, SIDEBAR_ACCOUNT_TABS, SIDEBAR_TABS } from "layouts";
import { APP_ROUTES } from "../../../../views/routes/store/constant";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { inviteUserState, userDetailsState } from "views/roles/store";
import { getJsonToParams } from "utils";
import { API_URL } from "constant";
import { assetListPrivateState, ASSETS_STATUS } from "views/mint-asset";
import { assetListSbaState } from "views/sba7";
// import { dvpListState } from "views";


const { USER_LOGIN } = APP_ROUTES;

export const Sidebar = () => {
  const [isCollapsed] = useState(false);
  const [collapseRoute, setCollapseRoute] = useState<any>(new Set([]));
  const [privatCount, setPrivatCount] = useState(0);
  const [sbaCount, setSbaCount] = useState(0);
  // const [dvpCount, setDvpCount] = useState(0);  
  const userDataAccess = useRecoilValue(userDetailsState);
  const setVisibleInvite = useSetRecoilState(inviteUserState);
  const setAssetList = useSetRecoilState(assetListSbaState);
  const setAssetListPrivate = useSetRecoilState(assetListPrivateState);
  //  const setDvpState = useSetRecoilState(dvpListState);
  // hooks
  const { get: getAsset,data:assetData } = useNetwork();
  const { get: getAssetPrivate, data: privateData } = useNetwork();

  // const { get: getDvp, data: dvpData } = useNetwork();

  const navigate = useNavigate();
  //const [isOpen, setIsOpen] = useState(true);
  const [isOpen] = useState(true);
  const { pathname } = useLocation();
  // const { post: logoutApi } = useNetwork();
  const { deleteCookie } = useCookie();
  const { isAccess } = useAccess();


  // const setUserToken = useSetRecoilState(userTokenState);

  useEffect(() => {
    if (assetData?.message === "ok") {
      setAssetList({ loading: false, data: assetData?.data });
    } else {
      setAssetList({ loading: false });
    }
  }, [assetData]);

    useEffect(() => {
      if (privateData?.message === "ok") {
        setAssetListPrivate({ loading: false, data: privateData?.data });
      } else {
        setAssetListPrivate({ loading: false });
      }
    }, [privateData]);
    
    // useEffect(() => {
    //   if (dvpData?.message === "ok") {
    //     setDvpState({ loading: false, data: dvpData?.data });
    //   } else {
    //     setDvpState({ loading: false });
    //   }
    // }, [dvpData]);

  const handleFetchSba = useCallback(() => {
      getAsset(
        API_URL.ASSETS +
          getJsonToParams({
            limit: 10,
            offset: 0,
            type: "sba7",
            status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
          })
      );
  }, []);

   const handleFetchPrivate = useCallback(() => {
     getAssetPrivate(
       API_URL.ASSETS +
         getJsonToParams({
           limit: 10,
           offset: 0,
           type: "privates",
           status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
         })
     );
   }, []);

  //  const handleFetchDvp = useCallback(() => {
  //    getDvp(
  //      API_URL.userDvp +
  //        getJsonToParams({
  //          limit: 10,
  //          offset: 0,
  //          status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
  //        })
  //    );
  //  }, []);

    useEffect(() => {
      const dataRef = ref(
        database,
        `Pending-Assets-${ENVIRONMENT}/sba7/counter`
      );

      const unsubscribe = onValue(dataRef, (snapshot: { val: () => any; }) => {
        const data = snapshot.val();
         setSbaCount(data);
          handleFetchSba();
          
      });

      return () => unsubscribe();
    }, []);

      useEffect(() => {
        const dataRef = ref(
          database,
          `Pending-Assets-${ENVIRONMENT}/privates/counter`
        );

        const unsubscribe = onValue(
          dataRef,
          (snapshot: { val: () => any }) => {
            const data = snapshot.val();
            setPrivatCount(data);
            handleFetchPrivate();
          }
        );

        return () => unsubscribe();
      }, []);

// TO:DO once we start getting sidebar count we will show. 
        // useEffect(() => {
        //   const dataRef = ref(
        //     database,
        //     `DVP-${ENVIRONMENT}/counter`
        //   );

        //   const unsubscribe = onValue(
        //     dataRef,
        //     (snapshot: { val: () => any }) => {
        //       const data = snapshot.val();
        //       setDvpCount(data);
        //       handleFetchDvp();
        //     }
        //   );

        //   return () => unsubscribe();
        // }, []);

    
  // this will manage the logout
  const onLogout = useCallback(() => {
    localStorage.clear();
    deleteCookie("userDetails");
    window.location.href = USER_LOGIN;
  }, [deleteCookie]);

  // this will change the tabs
  const handleChangeTab = useCallback(
    (tabName: string) => {
      if (tabName === "LOGOUT") {
        onLogout();
      }
      if (tabName !== "LOGOUT") {
        navigate(`/${tabName}`);
      }
      setVisibleInvite(false);
    },
    [navigate, onLogout]
  );

  const mapProfileTabs = useMemo(
    () => (
      <>
        <div className="anything">
          <div className="sidebar__item">
            <div className="sidebar_textIcon">
              <div className="user-profile">
                {userDataAccess?.data?.firstName?.charAt(0)}{" "}
                {userDataAccess?.data?.lastName?.charAt(0)}
              </div>
              <div className="sidebar__text">
                {userDataAccess?.data?.firstName}{" "}
                {userDataAccess?.data?.lastName}
              </div>
            </div>
          </div>
          {SIDEBAR_ACCOUNT_TABS.tabs.map(({ ICON, TAB_INDEX, TITLE }) => {
            return (
              <div
                className="sidebar__item"
                onClick={() => handleChangeTab("LOGOUT")}
                key={`${TAB_INDEX}-${TITLE}`}
              >
                <div className="sidebar_textIcon">
                  <i className={`sidebar__icon ${ICON}`} />
                  <div className="sidebar__text">{TITLE}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    ),
    [handleChangeTab]
  );

  const handleToggle = (key: string) => {
    if (collapseRoute.has(key)) {
      collapseRoute.delete(key);
    } else {
      collapseRoute.add(key);
    }

    setCollapseRoute(new Set([...collapseRoute]));
  }
  const mapRender = useMemo(
    () => (
      <div className="anything">
        {SIDEBAR_TABS.tabs.map(
          ({ ICON, ROUTE, TAB_INDEX, TITLE, SUB_ROUTE }) => (
            <>
              {true && (
                <>
                  <div
                    key={`${TAB_INDEX}-${TITLE}`}
                    className={`sidebar__item ${
                      (pathname === `/${ROUTE}` ||
                        pathname.includes(`/${ROUTE}`)) &&
                      "sidebar__item--active"
                    }`}
                    onClick={() =>
                      SUB_ROUTE ? handleToggle(ROUTE) : handleChangeTab(ROUTE)
                    }
                  >
                    <div className="sidebar_textIcon">
                      <i className={`sidebar__icon ${ICON}`} />
                      <div className="sidebar__text">{TITLE}</div>
                    </div>
                    {/* To:do once we get count */}
                    {/* {TITLE === "DVP" && dvpCount > 0 && (
                      <div className="sidebar__count">
                        {dvpCount > 9 ? "9+" : dvpCount}
                      </div>
                    )} */}
                    {SUB_ROUTE && !isCollapsed && (
                      <i
                        style={{ fontSize: "24px", marginLeft: "54px" }}
                        className={`ri ${
                          collapseRoute.has(ROUTE)
                            ? "ri-arrow-up-s-line"
                            : "ri-arrow-down-s-line"
                        } `}
                        id={ICON}
                      />
                    )}
                  </div>
                  {SUB_ROUTE &&
                    (isCollapsed || collapseRoute.has(ROUTE)) &&
                    SUB_ROUTE?.map(({ ROUTE, TITLE }) => {
                      return (
                        <>
                          {(isAccess(TITLE) || TITLE === SBA7A) && (
                            <div
                              key={ROUTE}
                              className={`sidebar__item subPadding ${
                                (pathname === `/${ROUTE}` ||
                                  pathname.includes(`/${ROUTE}`)) &&
                                "sidebar__item--active "
                              }`}
                              onClick={() => handleChangeTab(ROUTE)}
                            >
                              <div
                                className="sidebar__text"
                                onClick={() => handleChangeTab(ROUTE)}
                              >
                                {!isCollapsed && TITLE}
                              </div>
                              {privatCount > 0 && TITLE === "Private Stock" && (
                                <div className="sidebar__count">
                                  {privatCount > 9 ? "9+" : privatCount}
                                </div>
                              )}
                              {TITLE === SBA7A && sbaCount > 0 && (
                                <div className="sidebar__count">
                                  {sbaCount > 9 ? "9+" : sbaCount}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                </>
              )}
            </>
          )
        )}
      </div>
    ),
    [
      isAccess,
      pathname,
      isCollapsed,
      collapseRoute,
      handleToggle,
      handleChangeTab,
    ]
  );

  const handleLogoClick = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  // const handleOpen = useCallback(() => {
  // 	setIsOpen((pre) => !pre);
  // 	// eslint-disable-next-line
  // }, []);

  return (
    <div
      className={`sidebar-container ${
        isOpen ? "open-sidebar" : "sidebar-collapse"
      }`}
      style={{ width: isOpen ? "242px" : "72px" }}
    >
      <div
        onClick={() => handleLogoClick("/businesses")}
        className="sidebar-container__brandLogo"
      >
        <Image
          fileName="Liquidity_Blue_Logo"
          className="sidebar-container__brandLogo__img"
          url="https://exchange.pp.satschel.com/media/images/Liquidity_Blue_Logo.png"
        />
      </div>
      <div className="sidebar-tabs-container">
        {/* <div className="sidebar-toggle-btn" onClick={handleOpen}>
					<i
						className={`${
							isOpen
								? 'ri-arrow-left-s-line left-accor'
								: 'ri-arrow-right-s-line right-accor'
						}`}
					/>
				</div> */}
        <div className="sidebar">{mapRender}</div>
        <div className="sidebar">{mapProfileTabs}</div>
      </div>
    </div>
  );
};
