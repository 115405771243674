import { RevenueSummary } from "./summary";
import { PrivateEquity } from "./privateEquity";
import { SbaChart } from "./sba";

import "./revenue.scss";
import { SbaClassic } from "./sbaClassic";
import { SbaDutch } from "./sbaDutch";
import { DatePicker } from "@storybook";

export const Revenue = () => {
  return (
    <div className="revenue">
      <div className="revenue__calender-btn">
        <DatePicker />
      </div>
      <div className="revenue-summary">
        <RevenueSummary iconClass="dollar" />
        <RevenueSummary
          totalAmount="$49284.50"
          profit="(+12.2%)"
          icon="ri-coin-fill"
          iconClass="coin"
          revenueLabel= "Trading"
        />
        <RevenueSummary icon="ri-auction-line" iconClass="auction"  revenueLabel= "Auction" />
      </div>

      <div className="revenue-charts">
        <div className="revenue-chart">
          {" "}
          <PrivateEquity />
        </div>
        <div className="revenue-chart">
          <SbaChart />
        </div>
        <div className="revenue-chart">
          <SbaClassic />
        </div>
        <div className="revenue-chart">
          <SbaDutch />
        </div>
      </div>
    </div>
  );
};
