export const RevenueSummary = ({
  totalAmount,
  profit,
  icon,
  iconClass,
  revenueLabel
}: any) => {
  
  return (
    <div className="revenue--summary">
      <div className="revenue--summary-left">
        <div className={`revenue--summary-icon icon-${iconClass ?? ""}`}>
          <i className={icon ?? "ri-money-dollar-circle-fill"}></i>
        </div>

        <div className="revenue-total">
          {" "}
          {totalAmount ?? "$2,192,932.50"}
          <div className="revenue--summary-label"> { revenueLabel ?? "Total revenue"} </div>
        </div>
      </div>
      <div className="revenue--summary-profit">{profit ?? "(+7.6%)"}</div>
    </div>
  );
};
