// src/firebase.js
import { getApp, getApps, initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // For Realtime Database
import { getFirestore } from "firebase/firestore"; // For Firestore
import { REACT_APP_FIREBASE as FIREBASE } from "envs";

const firebaseConfig = JSON.parse(FIREBASE);

console.log(firebaseConfig);
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

export const database = getDatabase(app); // For Realtime Database
export const firestore = getFirestore(app); // For Firestore
console.log(database);
