import "./mintTable.scss"; // Import your CSS file for styling

export const MintTable = () => {
  const data = [
    { name: "Asset 1", y: 30, color: "#33B87A" },
    { name: "Asset 2", y: 40, color: "#458BF5" },
    { name: "Asset 3", y: 20, color: "#8B45F5" },
    { name: "Asset 4", y: 20, color: "#F55353" },
    { name: "Asset 5", y: 20, color: "#3C65D6" },
    { name: "Asset 6 ", y: 20, color: "#F5AF45" },
  ];

  return (
    <div className="table-container mint-table">
      <div className="table-header" style={{ backgroundColor: "#EDF1FC" }}>
        <div className="table-cell asset-color"></div>
        <div className="table-cell">Assets</div>
        <div className="table-cell">Minting</div>
        <div className="table-cell">%</div>
      </div>
      {data.map((item, index) => (
        <div className="table-row" key={index}>
          <div
            className="table-cell asset-color"
            style={{ backgroundColor: item.color }}
          ></div>
          <div className="table-cell">{item.name}</div>
          <div className="table-cell">{item.y}</div>
          <div className="table-cell">{item.y}%</div>
        </div>
      ))}
    </div>
  );
};
