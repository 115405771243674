import { atom } from "recoil";
import { IloginForm, IuserAuth, ILoginRoutes } from "./types";

export const userAuthState = atom<IuserAuth>({
  key: "is-loggedin-state-key",
  default: {
    isLoggedIn: false,
    step: "CREDS",
  },
});

export const userTokenState = atom<string>({
  key: "user-token-state-key",
  default: "",
});

export const loginFormState = atom<IloginForm>({
  key: "login-form-state-key",
  default: {
    email: "",
    // code: "",
  },
});

export const LoginRoutesState = atom<ILoginRoutes>({
  key: "login-route-state",
  default: "email",
});

export const holdLoginState = atom<boolean>({
  key: "login-hold-state",
  default: true,
});

export const SendOTPState = atom<boolean>({
  key: "send-otp",
  default: false,
});

export const MobileVerificationState = atom<string>({
  key: "mobile-verification-id",
  default: "",
});


export const ApprovalStatusState = atom<string>({
  key: "approval-status-state-key",
  default: "",
});

export const OptionsResponseState = atom<any>({
  key: "options-response-state-key",
  default: null,
});

export const userLoggedInDetails = atom<any>({
  key: "user-logged-in-details",
  default: {
    "isLogin": false,
    "isVerifiedEmail": false,
    "_id": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "countryCode": "",
    "phone": ""
  }
});

export const sendCodeTimerState = atom<any>({
  key: "send-code-timer",
  default: null,
});