import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useSearchParams } from "react-router-dom";

import { Sba7 } from "./sba";
import { initalFilterStaate } from "./store";
import { getObjectFromUseSearchParams } from "utils";
import { ASSETS_STATUS } from "views/mint-asset";

export const SbaAsset = () => {
  
  const [activeTab, setActiveTab] = useState(1);
  const initialfiter = useRecoilValue(initalFilterStaate);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
  const filterObj = getObjectFromUseSearchParams(searchParams); 
  filterObj.status === "APPROVED" && setActiveTab(2);
  },[searchParams])

  const handleTabClick = useCallback((tabNumber: React.SetStateAction<number>) => {
     const { immediate, ..._filters } = initialfiter;
    if (tabNumber === 2) {
      setSearchParams({ ..._filters, status: ASSETS_STATUS.APPROVED });
    } else {
       setSearchParams({
         ..._filters,
         status: `${ASSETS_STATUS.PENDING},${ASSETS_STATUS.REJECTED}`,
       });
    }
    setActiveTab(tabNumber);
  },[initialfiter]);

  return (
    <>
      <div className="minted-asset">
        <div className="tabs">
          <div
            className={`tab revenue-chart__label ${
              activeTab === 1 ? "active" : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Approval Pending
          </div>
          <div
            className={`tab revenue-chart__label ${
              activeTab === 2 ? "active" : ""
            }`}
            onClick={() => handleTabClick(2)}
          >
            Listed
          </div>
        </div>

        <div className="tab-content">
          {activeTab === 1 && <Sba7 />}
          {activeTab === 2 && (
            <>
              <Sba7 />
            </>
          )}
        </div>
      </div>
    </>
  );
};
