import { ISidebarTabs } from "./types";

export const moduleNameAsPerRoute: any = {
  "/roles": "Roles & permissions",
  "/accounts": "Accounts",
  "/revenue": "Revenue & Analytics",
  "/private-stock": "Private Stock",
  //'/auction': "Auction",
  "/activity-logs": "Activity Logs",
  "/products": "Products",
  "/delevery-payments": "DVP",
  "/sba7": "SBA7",
   "/chats": 'chats',
};

export const SIDEBAR_TAB = {
  USER_DETAILS: "User Details",
  REVENUE: "Revenue & Analytics",
  AUCTION: "Auction",
  SETTING: "Settings",
  ACTIVITY_LOGS: "Activity Logs",
  CHATS: "Chats",
  ACCOUNTS: "Accounts",
  MINTED_ASSET: "Private Stock",
  SBA7: "SBA7",
  PRODUCTS: "Products",
  APIDESIGNER: "API Designer",
  ROLES: "Roles & permissions",
  DP: "DVP",
  APPMANAGEMENT: "App management",
};

export const ACTIVE_TABS = {
  REVENUE: "revenue",
  USER_DETAILS: "user-details",
  AUCTION: "auction",
  ROLES: "roles",
  ACTIVITY_LOGS: "activity-logs",
  CHATS: "chats",
  ACCOUNTS: "accounts",
  PRODUCTS: "products",
  MINTED_ASSET: "private-stock",
  SBA7: "sba7",
  APIDESIGNER: "api-designer",
  DP: "delevery-payments",
  APP: "app-management",
};

export const SBA7A = "SBA(7a)";

export const SIDEBAR_TABS: ISidebarTabs = {
  label: "Main Menu",
  tabs: [
    {
      ROUTE: ACTIVE_TABS.ACCOUNTS,
      TITLE: SIDEBAR_TAB.ACCOUNTS,
      ICON: "ri-account-circle-fill",
      TAB_INDEX: 1,
    },
    {
      ROUTE: ACTIVE_TABS.REVENUE,
      TITLE: SIDEBAR_TAB.REVENUE,
      ICON: "ri-money-dollar-circle-fill",
      TAB_INDEX: 2,
    },
    {
      ROUTE: ACTIVE_TABS.AUCTION,
      TITLE: SIDEBAR_TAB.PRODUCTS,
      ICON: "ri-box-3-line",
      TAB_INDEX: 3,
      SUB_ROUTE: [
        {
          ROUTE: ACTIVE_TABS.MINTED_ASSET,
          TITLE: SIDEBAR_TAB.MINTED_ASSET,
          ICON: "ri-file-3-fill",
        },
        {
          ROUTE: ACTIVE_TABS.SBA7,
          TITLE: SBA7A,
          ICON: "ri-file-3-fill",
        },
        //"Temporarily comment out the auction routes"
        // {
        //   ROUTE: ACTIVE_TABS.AUCTION,
        //   TITLE: SIDEBAR_TAB.AUCTION,
        //   ICON: "ri-auction-line",
        // },
        {
          ROUTE: ACTIVE_TABS.APIDESIGNER,
          TITLE: SIDEBAR_TAB.APIDESIGNER,
          ICON: "ri-file-3-fill",
        },
      ],
    },

    {
      ROUTE: ACTIVE_TABS.DP,
      TITLE: SIDEBAR_TAB.DP,
      ICON: "ri-file-copy-2-fill",
      TAB_INDEX: 4,
    },
    {
      ROUTE: ACTIVE_TABS.ACTIVITY_LOGS,
      TITLE: SIDEBAR_TAB.ACTIVITY_LOGS,
      ICON: "ri-list-check-2",
      TAB_INDEX: 5,
    },
    {
      ROUTE: ACTIVE_TABS.CHATS,
      TITLE: SIDEBAR_TAB.CHATS,
      ICON: "ri-chat-4-fill",
      TAB_INDEX: 6,
    },
    {
      ROUTE: ACTIVE_TABS.ROLES,
      TITLE: SIDEBAR_TAB.SETTING,
      ICON: "ri-settings-5-line",
      TAB_INDEX: 7,
      SUB_ROUTE: [
        {
          ROUTE: ACTIVE_TABS.ROLES,
          TITLE: SIDEBAR_TAB.ROLES,
          ICON: "ri-file-3-fill",
        },
        {
          ROUTE: ACTIVE_TABS.APP,
          TITLE: SIDEBAR_TAB.APPMANAGEMENT,
          ICON: "ri-file-3-fill",
        },
      ],
    },
  ],
};

export const SIDEBAR_ACCOUNT_TABS = {
  label: "Account",
  tabs: [
    {
      TITLE: "Logout",
      ICON: "ri-logout-circle-line",
      TAB_INDEX: 7,
    },
  ],
};

