// ColumnBarGraph.tsx
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const barChatData = {
  chartData: {
    title: "Step-wise Data",
    XAxis: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

    data: [
      {
        name: "Live",
        data: [1, 3, 5, 2, 1, 4, 3],
      },
      {
        name: "Closed",
        data: [3, 1, 3, 2, 2, 1, 1],
      },
      {
        name: "Review pending",
        data: [1, 3, 2, 3, 1, 2, 1],
      },
      {
        name: "Sold",
        data: [1, 3, 5, 3, 2, 1, 1],
      },
    ],
  },
};

export const ColumnBarGraph: React.FC = () => {
  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },

    credits: {
      enabled: false,
    },

    colors: ["#458BF5", "#33B87A", "#8B45F5", "#F5AF45"],

    title: {
      text: "",
      align: "left",
      style: {
        color: "#000000",
        fontWeight: "600",
        fontSize: "16px",
      },
    },

    xAxis: {
      labels: {
        overflow: "justify",
      },
      categories: barChatData.chartData.XAxis,
    },

    yAxis: {
      min: 0,
      // max: 20,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: false,
      },
    },

    legend: {
      width: "100%",
      symbolRadius: 0,
      itemMarginTop: 2,
      title: {
        text: "",
        style: {
          color: "#878C99",
          fontWeight: "500",
          fontSize: "12px",
        },
      },
      itemStyle: {
        color: "#747E99",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
      },
      itemHoverStyle: {
        color: "#000000",
      },
    },

    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
    },

    exporting: { enabled: false },

    plotOptions: {
      series: {
        maxPointWidth: 30,
      } as any,
      column: {
        stacking: "normal",
        borderColor: null as any,
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    },

    series: barChatData.chartData.data as any,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
