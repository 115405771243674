// PieChartWithTable.tsx
import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const PieChartWithTable: React.FC = () => {
  const data = [
    { name: "Asset 1", y: 30, color: "#33B87A" },
    { name: "Asset 2", y: 40, color: "#458BF5" },
    { name: "Asset 3", y: 20, color: "#8B45F5" },
    { name: "Asset 4", y: 20, color: "#F55353" },
    { name: "Asset 5", y: 20, color: "#3C65D6" },
    { name: "Asset 6 ", y: 20, color: "#F5AF45" },
  ];

  // Calculate the total value
  const totalValue = data.reduce((total, item) => total + item.y, 0);

  const chartRef: any = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;

      // Calculate the position for the total label
      const centerX = chart.plotWidth / 2 + chart.plotLeft;
      const centerY = chart.plotHeight / 2 + chart.plotTop;

      // Create and add the total label
      const totalLabel = chart.renderer
        .label(`Total Minting: ${totalValue}`, centerX, centerY)
        .css({
          color: "black", // Customize text color
          fontSize: "16px", // Customize font size
        })
        .attr({
          fill: "white", // Customize label background color
        })
        .add();

      // Center the label in the container
      totalLabel.align({
        align: "center",
        verticalAlign: "middle",
        x: -80, // Adjust x offset to ensure proper centering
        y: 0, // Adjust y offset to ensure proper centering
      });
    }
  }, [totalValue]);

  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // Setting innerSize creates a donut chart
        dataLabels: {
          enabled: false, // Disable data labels for individual slices
        },
      },
    },
    series: [
      {
        name: "Data",
        data: data,
        center: ["50%", "50%"], // Center the pie chart in the container
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};
