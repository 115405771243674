// LineChart.tsx
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Sample data
const seriesData = [
  {
    name: "Total Value",
    data: [3.5, 2.9, 3.8, 2.8, 3.1, 3, 3.5],
    color: "#458BF5",
  },

  {
    name: "Solid Value",
    data: [1.75, 0.9, 2, 0.8, 1.1, 1, 1.5],
    color: "#F5AF45",
  },
  {
    name: "Revenue",
    data: [0.5, 0.25, 0.3, 0.6, 0.25, 0.1, 0.5],
    color: "#33B87A",
  },

  // Add more series as needed
];

export const LineChart: any = ({ lineChartData }: any) => {
  const options: Highcharts.Options = {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: "",
      },
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
      // labels: [0, 0.5, 1, 1.5, 2, 2.5],
      // labels: {
      //   formatter: function () {
      //     // Custom Y-axis label formatting
      //     return (this.value as number) / 2 + " units";
      //   },
      // },
    },
    series: (lineChartData ?? (seriesData as any)).map((series: any) => ({
      ...series,
      marker: {
        symbol: "circle",
        radius: 4,
        fillColor: "#FFFFFF",
        lineWidth: 2,
        lineColor: null, // null for default color
      },
    })),
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
