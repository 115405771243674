import { ColumnBarGraph, LineChart, PieChartWithTable } from "@storybook";
import React, { useState } from "react";
import { MintTable } from "./mintTable";

const lineData: any[] = [
  {
    name: "Revenue from Brokerage",
    data: [3.5, 2.9, 3.8, 2.8, 3.1, 3, 3.5],
    color: "#458BF5",
  },

  {
    name: "Revenue from asset minting",
    data: [0.5, 0.25, 0.3, 0.6, 0.25, 0.1, 0.5],
    color: "#33B87A",
  },

  // Add more series as needed
];

export const PrivateEquity = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="revenue-chart-wrapper">
      <div className="revenue-chart__label">Trading</div>
      <h3>Private Equity</h3>
      <div>
        <div className="tabs">
          <div
            className={`tab revenue-chart__label ${
              activeTab === 1 ? "active" : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Revenue{" "}
          </div>
          <div
            className={`tab revenue-chart__label ${
              activeTab === 2 ? "active" : ""
            }`}
            onClick={() => handleTabClick(2)}
          >
            Mint
          </div>
          <div
            className={`tab revenue-chart__label ${
              activeTab === 3 ? "active" : ""
            }`}
            onClick={() => handleTabClick(3)}
          >
            Volume
          </div>
        </div>

        <div className="tab-content">
          {activeTab === 1 && <LineChart lineChartData={lineData as any} />}
          {activeTab === 3 && <ColumnBarGraph />}
          {activeTab === 2 && (
            <div className="dount-chart">
              <div className="dount--chart">
                <PieChartWithTable />{" "}
              </div>
              <div className="dount--chart donut-table">
                <MintTable />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
